import { AppConfig } from "../../app.config";
import { Datetime } from "../../utilities/datetime";
import { Store } from "../store/store";

export class Session {
  private readonly _store = new Store();

  getSessionExpiry(): number {
    return Datetime.getFutureTimestamp(AppConfig.SESSION_DURATION_MINUTES);
  }

  isNewSession(): boolean {
    const lastPageView = this._store.getLastPageViewTimestamp();

    if (lastPageView) {
      const sessionDuration =
        AppConfig.SESSION_DURATION_MINUTES.toMilliseconds();
      const sinceLastPageView = Datetime.timestamp - lastPageView;

      return sinceLastPageView > sessionDuration;
    } else {
      return true;
    }
  }
}
