import { API } from "../service/api";
import { Context } from "../utilities/context";
import { Datetime } from "../utilities/datetime";
import { DeviceTypes } from "../utilities/device_types";
import { Fingerprinting } from "../utilities/fingerprinting";
import { Geolocation } from "../types/geolocation";
import { Geolocator } from "../service/geolocator/geolocator";
import { RTSRequest } from "../types/rts_request";
import { Session } from "../service/session/session";
import { Store } from "../service/store/store";
import { VisitorTypes } from "../utilities/visitor_types";
import { getDeviceType } from "../service/device";
import { isUndefined } from "../../service/lang";

export class Visitor {
  private static readonly _session = new Session();
  private static readonly _store = new Store();

  private static _pageViewId: string = "";
  private static _timeSpentPageView = 0;

  static get dealershipId(): number {
    return Context.dealershipId;
  }

  static get deviceType(): DeviceTypes {
    return this._store.getDeviceType() ?? DeviceTypes.DESKTOP;
  }

  static get firstVisit(): number {
    return this._store.getFirstVisitTimestamp() || Datetime.timestamp;
  }

  static get geolocation(): Geolocation | null | undefined {
    return this._store.getGeolocation();
  }

  static get id(): string {
    return this._store.getVisitorId() ?? "";
  }

  static get isBlocked(): boolean {
    return !!this._store.getVisitorBlocked();
  }

  static get isRegistered(): boolean {
    return !!this._store.getVisitorRegistered();
  }

  static get pageViewId(): string {
    return this._pageViewId ?? "";
  }

  static get pageViews(): number {
    return this._store.getPageViewsCount() ?? 0;
  }

  static get sessionId(): string {
    const sessionId = this._store.getSessionId();

    if (!!sessionId) {
      const expiry = this._session.getSessionExpiry();
      this._store.extendSessionIdExpiry(expiry);
    }

    return this.id ? sessionId ?? "" : "";
  }

  static get timeSpent(): number {
    return this._store.getTimeSpentSeconds() ?? 0;
  }

  static get timeSpentPageView(): number {
    return this._timeSpentPageView;
  }

  protected static get timestamp(): number | undefined {
    return this._store.getLastPageViewTimestamp();
  }

  static get type(): VisitorTypes {
    return this._store.getVisitorType() ?? VisitorTypes.NEW;
  }

  static get visits(): number {
    return this._store.getVisitsCount() ?? 0;
  }

  static get zip(): string {
    return this.geolocation?.zip ?? "";
  }

  static addTimeSpent(value: number): void {
    this._timeSpentPageView = this._timeSpentPageView + value;
    this._store.setTimeSpentSeconds(this.timeSpent + value);
  }

  static block(): void {
    this._store.setVisitorBlocked(true);
  }

  static incrementPageViews(): void {
    this._store.setPageViewsCount(this.pageViews + 1);
  }

  static incrementVisits(): void {
    this._store.setVisitsCount(this.visits + 1);
  }

  static initializeIds = async (fingerprint?: string) => {
    try {
      const data = RTSRequest.build();

      if (!Visitor.id) {
        data.fingerprint = fingerprint || (await Fingerprinting.getId());
      }

      const response = await API.postRTSData(data);

      if (response?.pageViewId) {
        this.setPageViewId(response.pageViewId);
      }

      if (response?.sessionId) {
        this.setSessionId(response.sessionId);
      }

      if (response?.visitorId) {
        this.setId(response.visitorId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  static initializeBlocked = async () => {
    const currentStatus = this._store.getVisitorBlocked();

    if (isUndefined(currentStatus) && Visitor.isRegistered && Visitor.id) {
      try {
        const response = await API.getBlockedStatus();
        Visitor.setBlocked(!!response?.blocked);
      } catch (error) {
        console.error(error);
      }
    }
  };

  static initializeGeolocation = async () => {
    if (isUndefined(this.geolocation)) {
      try {
        const geolocation = await Geolocator.getGeolocation();
        this.setGeolocation(geolocation);
      } catch (error) {
        this.setGeolocation(null);
      }
    } else {
      this.setGeolocation();
    }
  };

  static initialize = async (fingerprint?: string) => {
    this.setDeviceType();
    this.setFirstVisitTimestamp();
    this.setType();
    this.setVisits();
    await this.initializeIds(fingerprint);
    await this.initializeBlocked();
    await this.initializeGeolocation();
  };

  static register(): void {
    this._store.setVisitorRegistered(true);
  }

  static setBlocked(blocked: boolean): void {
    this._store.setVisitorBlocked(blocked);
  }

  protected static setDeviceType(): void {
    if (!this._store.getDeviceType()) {
      this._store.setDeviceType(getDeviceType());
    }
  }

  protected static setFirstVisitTimestamp(): void {
    if (!this._store.getFirstVisitTimestamp()) {
      this._store.setFirstVisitTimestamp(Datetime.timestamp);
    }
  }

  static setGeolocation(geolocation?: Geolocation | null): void {
    const expiry = this._session.getSessionExpiry();

    if (isUndefined(geolocation)) {
      this._store.extendGeolocationExpiry(expiry);
    } else {
      this._store.setGeolocation(geolocation, expiry);
    }
  }

  static setId(id: string): void {
    this._store.setVisitorId(id);
  }

  static setPageViewId(pageViewId: string): void {
    this._pageViewId = pageViewId;
  }

  static setSessionId(sessionId: string): void {
    const expiry = this._session.getSessionExpiry();

    if (!!this.sessionId) {
      this._store.extendSessionIdExpiry(expiry);
    } else {
      this._store.setSessionId(sessionId, expiry);
    }
  }

  protected static setType(): void {
    if (!this._store.getVisitorType()) {
      this._store.setVisitorType(VisitorTypes.NEW);
    } else if (this._session.isNewSession()) {
      this._store.setVisitorType(VisitorTypes.RETURNING);
    }
  }

  protected static setVisits(): void {
    if (this._session.isNewSession()) {
      this.incrementVisits();
    }
  }
}
