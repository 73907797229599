import { findById } from "../../service/dom/dom_utilities";

export class Integration {
  private static _dealershipUUID: string = "";

  static get dealershipUUID() {
    if (!this._dealershipUUID) {
      const params = new URLSearchParams(window.location.search);
      const idParam = params.get("qoreai_id");
      const script = findById(process.env.SCRIPT_ID!);
      const integratorId = script?.dataset?.[process.env.INTEGRATOR_ID_ATTR!];

      if (!idParam && !integratorId) {
        throw new Error("No integrator ID provided");
      }

      this._dealershipUUID = `${idParam || integratorId}`;
    }

    return this._dealershipUUID;
  }

  static get isSuspended() {
    return (
      new URLSearchParams(window.location.search).get("qoreai_suspend") !== null
    );
  }
}
