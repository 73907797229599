import { ChatStatuses } from "../utilities/chat_statuses";
import Storage from "../utilities/storage/storage";
import { StorageKeys } from "../utilities/storage/storage_keys";

export abstract class Preferences {
  static get chatStatus(): ChatStatuses {
    return Storage.getKeyData(StorageKeys.CHAT_STATUS) ?? ChatStatuses.closed;
  }

  static get isChatOpened(): boolean {
    return this.chatStatus === ChatStatuses.opened;
  }

  static setChatStatus(update: ChatStatuses): void {
    Storage.setKey(StorageKeys.CHAT_STATUS, { data: update });
  }
}
