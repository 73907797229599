// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j8NGR3W_wfpig3N8jaVd {
  align-items: center;
  background-color: white;
  border-radius: 10px 0 0 10px;
  justify-content: center;
  height: 100%;
  max-width: 100%;
  width: 600px;
  position: fixed;
  right: 0;
  bottom: calc(100% + 20px);
  z-index: 2147483647;
}

.nwShYfyDaTr4KVk8esPt {
  border: none;
  border-radius: 10px 0 0 10px;
  height: 100%;
  width: 100%;
}

.Oaz3BJaVIQn9fV0GMzCa {
  animation: Oaz3BJaVIQn9fV0GMzCa 0.5s forwards;
}

.cpYbc8q2ETKoNaRHd6Uf {
  animation: cpYbc8q2ETKoNaRHd6Uf 0.5s forwards;
}

@keyframes Oaz3BJaVIQn9fV0GMzCa {
  100% {
    bottom: 0
  }
}

@keyframes cpYbc8q2ETKoNaRHd6Uf {
  0% {
    bottom: 0;
  }

  100% {
    bottom: calc(100% + 20px);
  }
}

@media only screen and (max-width: 600px) {
  .j8NGR3W_wfpig3N8jaVd {
    border-radius: unset;
  }

  .nwShYfyDaTr4KVk8esPt {
    border-radius: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/chat_window/chat_window.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,4BAA4B;EAC5B,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,eAAe;EACf,QAAQ;EACR,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,6CAAgC;AAClC;;AAEA;EACE,6CAAiC;AACnC;;AAEA;EACE;IACE;EACF;AACF;;AAEA;EACE;IACE,SAAS;EACX;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,oBAAoB;EACtB;;EAEA;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".container {\n  align-items: center;\n  background-color: white;\n  border-radius: 10px 0 0 10px;\n  justify-content: center;\n  height: 100%;\n  max-width: 100%;\n  width: 600px;\n  position: fixed;\n  right: 0;\n  bottom: calc(100% + 20px);\n  z-index: 2147483647;\n}\n\n.frame {\n  border: none;\n  border-radius: 10px 0 0 10px;\n  height: 100%;\n  width: 100%;\n}\n\n.slideIn {\n  animation: slideIn 0.5s forwards;\n}\n\n.slideOut {\n  animation: slideOut 0.5s forwards;\n}\n\n@keyframes slideIn {\n  100% {\n    bottom: 0\n  }\n}\n\n@keyframes slideOut {\n  0% {\n    bottom: 0;\n  }\n\n  100% {\n    bottom: calc(100% + 20px);\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .container {\n    border-radius: unset;\n  }\n\n  .frame {\n    border-radius: unset;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `j8NGR3W_wfpig3N8jaVd`,
	"frame": `nwShYfyDaTr4KVk8esPt`,
	"slideIn": `Oaz3BJaVIQn9fV0GMzCa`,
	"slideOut": `cpYbc8q2ETKoNaRHd6Uf`
};
export default ___CSS_LOADER_EXPORT___;
