import { isEmpty, isUndefined } from "../../lang";

import BaseURLService from "../../url/base/base_url_service";
import Factory from "../../factory";
import HTTPRequest from "../http_request";
import HTTPResponse from "../http_response";
import { NetworkEvent } from "../network_event";
import { NetworkStatus } from "../network_status";
import URLService from "../../url/url_service";

export default class BaseRequest {
  public static eventsKey: string = '_pi_rts_network_event';

  private _args: HTTPRequest;
  private _request: XMLHttpRequest | undefined;;
  private _url: URLService | undefined;

  public constructor(args: HTTPRequest) {
    this._args = args;
  }

  protected get args(): HTTPRequest {
    return this._args;
  }

  protected get endpoint(): string {
    return this.args.url;
  }

  protected get method(): string {
    return this.args.method as string;
  }

  protected get request(): XMLHttpRequest {
    if (isUndefined(this._request)) {
      this._request = new XMLHttpRequest();
    }

    return this._request;
  }

  protected get url(): URLService {
    if (isUndefined(this._url)) {
      this._url = Factory.instance.build(BaseURLService);
    }

    return this._url;
  }

  public execute<T>(): Promise<HTTPResponse<T>> {
    return new Promise<HTTPResponse<T>>((resolve, reject) => {
      this.request.onabort = (error) => {
        reject(error);
      };
      this.request.onerror = (error) => {
        this._notify(NetworkStatus.error);
        reject(error);
      };
      this.request.onreadystatechange = () => {
        if (this.request.readyState === this.request.DONE) {
          let data;

          try {
            data = JSON.parse(this.request.responseText);
          } catch (error) { }

          this._notify(NetworkStatus.done);
          resolve({ data: data, status: this.request.status });
        }
      };

      this.request.open(this.method, this.endpoint, true);
      this.setHeaders();
      this.send();
    });
  }

  private _notify(status: NetworkStatus): void {
    const data: NetworkEvent = {
      code: this.request.status,
      status: status,
      url: this.endpoint
    };
    const event = new window.CustomEvent(BaseRequest.eventsKey, { detail: data });
    window.dispatchEvent(event);
  }

  protected send(): void {
    const encoded = JSON.stringify(this.args.data);
    this.request.send(encoded);
  }

  protected setHeaders(): void {
    const headers = this.args.headers;

    if (!isEmpty(headers)) {
      for (let name in headers) {
        const key = name as keyof typeof headers;
        const value = headers[key];
        this.request.setRequestHeader(name, value);
      }
    }
  }
}