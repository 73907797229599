import BaseRequest from "./base_request";
import { isEmpty } from "../../lang";

export default class GetRequest extends BaseRequest {
  /**
   * @override
   */
  protected get endpoint(): string {
    let target = this.args.url;
    const params = this.url.objectToParams(this.args.data);

    if (!isEmpty(params)) {
      target += `?${params}`;
    }

    return target;
  }

  /**
   * @override
   */
  protected send(): void {
    this.request.send();
  }
}