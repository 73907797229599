import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

export class Fingerprinting {
  static getId = async () => {
    try {
      const agent = await FingerprintJS.load({
        apiKey: process.env.FINGERPRINTING_API_KEY!,
        endpoint: process.env.FINGERPRINTING_URL!,
      });
      const result = await agent.get();

      return result.visitorId;
    } catch (error) {
      console.error(error);
    }
  };
}
