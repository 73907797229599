import { Geolocation } from "../../types/geolocation";
import HTTPStatus from "../../../service/http/http_status";

export class Geolocator {
  static getGeolocation = async (): Promise<Geolocation> => {
    const response = await fetch(`${process.env.URL_GEOLOCATION}`);

    if (response.status === HTTPStatus.OK) {
      const data = await response.json();

      return {
        city: data.city,
        country: data.country,
        ip: data.query,
        latitude: data.lat,
        longitude: data.lon,
        timezone: data.timezone,
        zip: data.zip,
      };
    } else {
      throw new Error("Cannot fetch geolocation");
    }
  };
}
