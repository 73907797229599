import { DeviceTypes } from "../utilities/device_types";
import { Geolocation } from "./geolocation";
import { Tracker } from "../service/tracker/tracker";
import { Visitor } from "../model/visitor";
import { VisitorTypes } from "../utilities/visitor_types";

export type RegisterVisitorRequest = {
  dealershipId: number;
  metadata: {
    geolocation: Geolocation | null;
    pageViews: number;
    timeSpent: number;
    device: DeviceTypes;
    firstVisitDate: string;
    numberOfVisits: number;
    [key: string]: any;
  };
  rtsVisitorId: string;
  status: VisitorTypes;
};

export class RegisterVisitorRequestAdapter {
  private static readonly _tracker = new Tracker();

  static fromVisitor(): RegisterVisitorRequest {
    return {
      dealershipId: Visitor.dealershipId,
      metadata: {
        geolocation: Visitor.geolocation ?? null,
        pageViews: Visitor.pageViews,
        timeSpent: Visitor.timeSpent,
        device: Visitor.deviceType,
        firstVisitDate: new Date(Visitor.firstVisit).toISOString(),
        numberOfVisits: Visitor.visits,
        ...this._tracker.getCampaigns(),
      },
      rtsVisitorId: Visitor.id,
      status: Visitor.type,
    };
  }
}
