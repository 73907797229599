import { App } from "../../../app";
import { Backend } from "../../../service/backend/backend";
import { ChatState } from "./chat_state";
import { State } from "../../../model/state";

export abstract class BaseChatState implements ChatState {
  protected readonly backend = new Backend();
  readonly context: App;

  constructor(context: App) {
    this.context = context;
  }

  protected get hasChatWindow(): boolean {
    return document.body.contains(this.context.chatWindow);
  }

  async end(): Promise<void> {
    await this.backend.endChat();
  }

  async start(): Promise<void> {
    await this.backend.startChat();
    State.initiateChat();
  }

  async toggle(): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
