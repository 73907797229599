import { isUndefined } from "./lang";

export default class Factory {
  private static _instance: Factory;
  private _cache: Map<any, any> | undefined;

  private constructor() { }

  private get cache(): Map<any, any> {
    if (isUndefined(this._cache)) {
      this._cache = new Map();
    }

    return this._cache;
  }

  static get instance(): Factory {
    if (isUndefined(this._instance)) {
      this._instance = new Factory();
    }

    return this._instance;
  }

  build<T>(type: { new(): T }, useCache: boolean = true): T {
    let product: T;

    if (useCache) {
      if (!this.cache.has(type)) {
        const instance: T = new type();
        this.cache.set(type, instance);
      }

      product = this.cache.get(type);
    } else {
      product = new type();
    }

    return product;
  }

  callback(type: Function, thisArg: any): Function {
    if (!this.cache.has(type)) {
      this.cache.set(type, type.bind(thisArg));
    }

    return this.cache.get(type);
  }
}
