import { DeviceTypes } from "../utilities/device_types";
import { UAParser } from "ua-parser-js";

export const getDeviceType = (): DeviceTypes => {
  const deviceInfo = UAParser();

  if (deviceInfo.device.type === "mobile") {
    return DeviceTypes.MOBILE;
  } else if (deviceInfo.device.type === "tablet") {
    return DeviceTypes.TABLET;
  } else {
    return DeviceTypes.DESKTOP;
  }
};
