import { App } from "../../../app";
import { BaseChatState } from "./base_chat_state";
import { ChatClosed } from "./chat_closed";
import { ChatStatuses } from "../../../utilities/chat_statuses";
import { Preferences } from "../../../model/preferences";

export class ChatOpened extends BaseChatState {
  constructor(context: App) {
    super(context);
  }

  /**
   * @override
   */
  async toggle(): Promise<void> {
    this.context.hideChatWindow();
    this.context.showChatButton();

    const update = new ChatClosed(this.context);
    this.context.setChatState(update);
    Preferences.setChatStatus(ChatStatuses.closed);
  }
}
