import { App } from "../../../app";
import { BaseChatState } from "./base_chat_state";
import { ChatOpened } from "./chat_opened";
import { ChatStatuses } from "../../../utilities/chat_statuses";
import HTTPStatus from "../../../../service/http/http_status";
import { Preferences } from "../../../model/preferences";
import { RegisterVisitorRequestAdapter } from "../../../types/register_visitor_request";
import { Visitor } from "../../../model/visitor";

export class ChatClosed extends BaseChatState {
  constructor(context: App) {
    super(context);
  }

  /**
   * @override
   */
  async toggle(): Promise<void> {
    try {
      if (!Visitor.isRegistered) {
        await this.registerVisitor();
      }

      if (!this.hasChatWindow) {
        document.body.appendChild(this.context.chatWindow);
      }

      this.context.showChatWindow();
      this.context.hideChatButton();
      this.context.setChatState(new ChatOpened(this.context));
      Preferences.setChatStatus(ChatStatuses.opened);
    } catch (error) {
      console.log("Error registering visitor:", error);
    } finally {
      !this.context.isChatButtonEnabled && this.context.enableChatButton();
    }
  }

  private async registerVisitor(): Promise<void> {
    this.context.isChatButtonEnabled && this.context.disableChatButton();

    const request = RegisterVisitorRequestAdapter.fromVisitor();
    const response = await this.backend.registerVisitor(request);

    if (response.status === HTTPStatus.OK) {
      Visitor.register();
    } else {
      throw new Error(response.data);
    }
  }
}
