import { App } from "../src/app";
import { Integration } from "../src/utilities/integration";
import Storage from "../src/utilities/storage/storage";

if (Storage.isEnabled) {
  if (!Integration.isSuspended) {
    new App().run();
  }
} else {
  console.warn("Local storage is disabled");
}
