import { IFRAME_ID } from "../..";
import style from "./chat_window.css";
import theme from "../theme.css";

const frameSrc = fetch(`${process.env.URL_CHAT}`).then((response) =>
  response.text()
);

export function ChatWindow({
  visitorId,
}: {
  visitorId: string;
  dealershipId: number;
}) {
  const frame = document.createElement("iframe");
  frame.id = IFRAME_ID;
  frame.allow = "camera *; microphone *; display-capture *; geolocation *";
  frame.classList.add(style.frame);
  frame.dataset.userId = visitorId;

  if (process.env.MODE === "dev") {
    frame.src = process.env.URL_CHAT!;
  } else {
    frameSrc
      .then((html) => {
        frame.srcdoc = html;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const container = document.createElement("div");
  container.classList.add(style.container, theme.shadow);
  container.appendChild(frame);

  return container;
}
