import BaseRequest from './base_request';
import GetRequest from './get_request';
import HTTPMethods from '../http_methods';
import HTTPRequest from '../http_request';
import HTTPResponse from '../http_response';
import HTTPService from '../http_service';

export default class BaseHTTPService implements HTTPService {
  protected call<T>(args: HTTPRequest): Promise<HTTPResponse<T>> {
    let request;

    switch (args.method) {
      case HTTPMethods.GET:
        request = new GetRequest(args);
        break;
      case HTTPMethods.DELETE:
      case HTTPMethods.POST:
      case HTTPMethods.PUT:
        request = new BaseRequest(args);
        break;
      default:
        throw new Error(`${args.method} not implemented!`);
    }

    return request.execute();
  }

  delete<T>(args: HTTPRequest): Promise<HTTPResponse<T>> {
    args.method = HTTPMethods.DELETE;
    return this.call<T>(args);
  }

  get<T>(args: HTTPRequest): Promise<HTTPResponse<T>> {
    args.method = HTTPMethods.GET;
    return this.call<T>(args);
  }

  post<T>(args: HTTPRequest): Promise<HTTPResponse<T>> {
    args.method = HTTPMethods.POST;
    return this.call<T>(args);
  }

  put<T>(args: HTTPRequest): Promise<HTTPResponse<T>> {
    args.method = HTTPMethods.PUT;
    return this.call<T>(args);
  }
}
