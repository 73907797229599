/* ToDo: Insert parameters at pixel code for QoreaiAccount and Dealer Code */
export function SmartPixl(pixelId: string, pixelDomain: string): HTMLElement {
  pixelId =  pixelId.padStart(5, '0');
  const img = document.createElement("img");
  const SMART_PIXL_SOURCE = `https://smartpixl.com/12787/${pixelId}_${pixelDomain}_SMART.GIF`;
  img.src = SMART_PIXL_SOURCE;
  img.style.setProperty("display", "none", "important");

  const container = document.createElement("div");
  container.appendChild(img);

  return container;
}
