import { DeviceTypes } from "../../utilities/device_types";
import { Geolocation } from "../../types/geolocation";
import Storage from "../../utilities/storage/storage";
import { StorageKeys } from "../../utilities/storage/storage_keys";
import { VisitorTypes } from "../../utilities/visitor_types";

export class Store {
  private static instance: Store;

  static getInstance(): Store {
    if (!this.instance) {
      this.instance = new Store();
    }

    return this.instance;
  }

  extendGeolocationExpiry(expiry: number): void {
    Storage.extendExpiry(StorageKeys.GEOLOCATION, expiry);
  }

  extendSessionIdExpiry(expiry: number): void {
    Storage.extendExpiry(StorageKeys.SESSION_ID, expiry);
  }

  getCampaigns(): any {
    return Storage.getKeyData(StorageKeys.CAMPAINGS);
  }

  getDeviceType(): DeviceTypes | undefined {
    return Storage.getKeyData(StorageKeys.DEVICE_TYPE);
  }

  getFirstVisitTimestamp(): number | undefined {
    return Storage.getKeyData(StorageKeys.FIRST_VISIT_TIMESTAMP);
  }

  getGeolocation(): Geolocation | null | undefined {
    return Storage.getKeyData(StorageKeys.GEOLOCATION);
  }

  getActivityTimestamp(): number | undefined {
    return Storage.getKeyData(StorageKeys.ACTIVITY_TIMESTAMP);
  }

  getLastPageViewTimestamp(): number | undefined {
    return Storage.getKeyData(StorageKeys.LAST_PAGE_VIEW_TIMESTAMP);
  }

  getPageViewsCount(): number | undefined {
    return Storage.getKeyData(StorageKeys.PAGE_VIEWS_COUNT);
  }

  getSessionId(): string | undefined {
    return Storage.getKeyData(StorageKeys.SESSION_ID);
  }

  getTimeSpentSeconds(): number | undefined {
    return Storage.getKeyData(StorageKeys.TIME_SPENT_SECONDS);
  }

  getVisitorBlocked(): boolean | undefined {
    return Storage.getKeyData(StorageKeys.VISITOR_BLOCKED);
  }

  getVisitorId(): string | undefined {
    return Storage.getKeyData(StorageKeys.VISITOR_ID);
  }

  getVisitorRegistered(): boolean | undefined {
    return Storage.getKeyData(StorageKeys.VISITOR_REGISTERED);
  }

  getVisitorType(): VisitorTypes | undefined {
    return Storage.getKeyData(StorageKeys.VISITOR_TYPE);
  }

  getVisitsCount(): number | undefined {
    return Storage.getKeyData(StorageKeys.VISITS_COUNT);
  }

  setCampaigns(update: any): void {
    Storage.setKey(StorageKeys.CAMPAINGS, { data: update });
  }

  setDeviceType(deviceType: DeviceTypes): void {
    Storage.setKey(StorageKeys.DEVICE_TYPE, { data: deviceType });
  }

  setFirstVisitTimestamp(timestamp: number): void {
    Storage.setKey(StorageKeys.FIRST_VISIT_TIMESTAMP, {
      data: timestamp,
    });
  }

  setGeolocation(geolocation: Geolocation | null, expiry: number): void {
    Storage.setKey(StorageKeys.GEOLOCATION, {
      data: geolocation,
      expiry: expiry,
    });
  }

  setActivityTimestamp(timestamp: number): void {
    Storage.setKey(StorageKeys.ACTIVITY_TIMESTAMP, { data: timestamp });
  }

  setLastPageViewTimestamp(timestamp: number): void {
    Storage.setKey(StorageKeys.LAST_PAGE_VIEW_TIMESTAMP, {
      data: timestamp,
    });
  }

  setPageViewsCount(pageViewsCount: number): void {
    Storage.setKey(StorageKeys.PAGE_VIEWS_COUNT, {
      data: pageViewsCount,
    });
  }

  setSessionId(sessionId: string, expiry: number): void {
    Storage.setKey(StorageKeys.SESSION_ID, {
      data: sessionId,
      expiry: expiry,
    });
  }

  setTimeSpentSeconds(timeSpentSeconds: number): void {
    Storage.setKey(StorageKeys.TIME_SPENT_SECONDS, {
      data: timeSpentSeconds,
    });
  }

  setVisitorBlocked(isBlocked: boolean): void {
    Storage.setKey(StorageKeys.VISITOR_BLOCKED, { data: isBlocked });
  }

  setVisitorId(visitorId: string): void {
    Storage.setKey(StorageKeys.VISITOR_ID, { data: visitorId });
  }

  setVisitorRegistered(isRegistered: boolean): void {
    Storage.setKey(StorageKeys.VISITOR_REGISTERED, {
      data: isRegistered,
    });
  }

  setVisitorType(visitorType: VisitorTypes): void {
    Storage.setKey(StorageKeys.VISITOR_TYPE, { data: visitorType });
  }

  setVisitsCount(visits: number): void {
    Storage.setKey(StorageKeys.VISITS_COUNT, { data: visits });
  }
}
