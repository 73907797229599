import Duration from "./utilities/duration";
import { LocaleSchema } from "./locale_schema";
import TimeUnits from "../service/time_units";

export class AppConfig {
  private static readonly DEFAULT_CHAT_ACTIVITY_POLL_INTERVAL_SECONDS = "30";
  private static readonly DEFAULT_RTS_POLL_INTERVAL_SECONDS = "5";
  private static readonly DEFAULT_SESSION_DURATION_MINUTES = "30";
  private static readonly DEFAULT_TIME_COUNTER_INTERVAL = "1";
  private static readonly _chatActivityPollInterval = parseInt(
    process.env.CHAT_ACTIVITY_POLL_INTERVAL_SECONDS ??
      this.DEFAULT_CHAT_ACTIVITY_POLL_INTERVAL_SECONDS
  );
  private static readonly _rtsActivityPollInterval = parseInt(
    process.env.RTS_POLL_INTERVAL_SECONDS ??
      this.DEFAULT_RTS_POLL_INTERVAL_SECONDS
  );
  private static readonly _sessionDuration = parseInt(
    process.env.SESSION_DURATION_MINUTES ??
      this.DEFAULT_SESSION_DURATION_MINUTES
  );
  private static readonly _timeCounterInterval = parseInt(
    process.env.TIME_COUNTER_INTERVAL_SECONDS ??
      this.DEFAULT_TIME_COUNTER_INTERVAL
  );
  private static readonly URL_BASE_API = process.env.URL_BASE_API;
  private static readonly URL_BASE_RTS = process.env.URL_BASE_RTS;
  static readonly CHAT_ACTIVITY_POLL_INTERVAL = new Duration(
    this._chatActivityPollInterval,
    TimeUnits.seconds
  );
  static readonly LOCALE: LocaleSchema = LOCALE;
  static readonly RTS_POLL_INTERVAL = new Duration(
    this._rtsActivityPollInterval,
    TimeUnits.seconds
  );
  static readonly SESSION_DURATION_MINUTES = new Duration(
    this._sessionDuration,
    TimeUnits.minutes
  );
  static readonly TIME_COUNTER_INTERVAL_SECONDS = new Duration(
    this._timeCounterInterval,
    TimeUnits.seconds
  );
  static readonly URL = {
    REGISTER_VISITOR: `${this.URL_BASE_API}/visitor`,
    RTS: {
      CHAT: {
        VISITOR: `${this.URL_BASE_RTS}/chat-visitor`,
      },
    },
  };
  static readonly URL_CHAT = `${process.env.URL_CHAT}`;
  static readonly URL_CHAT_ACTIVITY = (id: string) =>
    `${this.URL_BASE_API}/visitors/${id}/chats/activity`;
  static readonly URL_VISITOR = `${this.URL_BASE_API}/visitor`;
  static readonly URL_VISITOR_CHAT = (id: string) =>
    `${this.URL_BASE_API}/visitors/${id}/chats`;
}
