import { isNull, isUndefined } from "../lang";

import CSS from "./css";
import DOMEvents from "./dom_events";
import InsertPositions from "./insert_positions";

export function addClass(element: any, className: string): void {
  element?.classList?.add?.(className);
}

export function appendHeadElement(element: any): void {
  window.document.head.appendChild(element);
}

export function attachDocumentListener(event: any, callback: any): void {
  attachListener(window.document, event, callback);
}

export function attachListener(element: any, event: any, callback: any): void {
  element.removeEventListener(event, callback);
  element.addEventListener(event, callback);
}

export function attachWindowListener(event: any, callback: any): void {
  attachListener(window, event, callback);
}

export function composedPathHasId(event: any, id: string): boolean {
  const elements = event.composedPath();
  return elements.some((e: any) => elementHasId(e, id));
}

export function createElement(name: string): any {
  return window.document.createElement(name);
}

export function createElementFromString(html: string) {
  const parser = new DOMParser();
  const product = parser.parseFromString(html, "text/html").body;

  if (product.children.length !== 1 || product.firstChild === null) {
    throw new Error("Invalid HTML source passed to createElement!");
  }

  return product.firstChild;
}

export function disable(element: any): void {
  element.disabled = true;
}

export function elementHasId(element: any, id: string): boolean {
  return element?.id === id;
}

export function enable(element: any): void {
  element.disabled = false;
}

export function fadeIn_(element: any): void {
  const fade = () => {
    let val = parseFloat(element.style.opacity);
    if (!((val += .1) > 1)) {
      element.style.opacity = val;
      window.requestAnimationFrame(fade);
    }
  };
  element.style.setProperty(CSS.Props.opacity, 0, CSS.Options.important);
  element.style.setProperty(CSS.Props.display, CSS.Values.block, CSS.Options.important);

  fade();
}

export function fadeIn(element: any, step: number = 0.03, display: string = CSS.Values.block): void {
  element.style.setProperty(CSS.Props.opacity, 0, CSS.Options.important);
  element.style.setProperty(CSS.Props.display, display, CSS.Options.important);

  let fade: any;
  (fade = () => {
    const current = parseFloat(element.style.opacity);

    if (current < 1) {
      const proposed = current + step;
      const update = proposed > 1 ? 1 : proposed;
      element.style.setProperty(CSS.Props.opacity, update, CSS.Options.important);
      window.requestAnimationFrame(fade);
    }
  })();
}

export function fadeOut(element: any, step: number = 0.03): void {
  if (!isUndefined(element)) {
    element.style.setProperty(CSS.Props.opacity, 1, CSS.Options.important);

    let fade: any;
    (fade = () => {
      const current = parseFloat(element.style.opacity);

      if (current > 0) {
        const proposed = current - step;
        const update = proposed < 0 ? 0 : proposed;
        element.style.setProperty(CSS.Props.opacity, update);
        window.requestAnimationFrame(fade);
      } else {
        element.style.setProperty(CSS.Props.display, CSS.Values.none, CSS.Options.important);
      }
    })();
  }
}

export function findById(id: string): any {
  const element = window.document.getElementById(id);
  return element === null ? undefined : element;
}

export function findBySelector(selector: string): any {
  return window.document.querySelector(selector);
}

export function findBySelectorAll(selector: string): any[] {
  return Array.prototype.slice.call(window.document.querySelectorAll(selector));
}

export function hasClass(element: any, className: string): boolean {
  return element?.classList?.contains?.(className) === true;
}

export function hide(element: any): void {
  element.style.setProperty(CSS.Props.display, CSS.Values.none, CSS.Options.important);
}

export function insertBodyContent(content: string, posistion: InsertPositions = InsertPositions.beforeEnd): void {
  window.document.body.insertAdjacentHTML(posistion, content);
}

export function insertHeadContent(content: string, position: InsertPositions = InsertPositions.beforeEnd): void {
  window.document.head.insertAdjacentHTML(position, content);
}

export function isActive(element: any): boolean {
  return element === window.document.activeElement;
}

export function isClickEvent(event: any): boolean {
  return event?.type === DOMEvents.click;
}

export function isDisplayed(element: any): boolean {
  return isUndefined(element) ? false : window.getComputedStyle(element).display !== CSS.Values.none;
}

export function isDocumentFocused(): boolean {
  let active;
  let hiddenKey;

  if (typeof document.hidden !== 'undefined') {
    hiddenKey = 'hidden';
    // @ts-ignore
  } else if (typeof document.msHidden !== 'undefined') {
    hiddenKey = 'msHidden';
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    hiddenKey = 'webkitHidden';
  }

  if (document.hasFocus !== undefined) {
    active = document.hasFocus();
  } else if (hiddenKey !== undefined) {
    // @ts-ignore
    active = !document[hiddenKey];
  } else {
    active = false;
  }

  return active;
}

export function isInDOM(selector: string): boolean {
  return !isNull(findBySelector(selector));
}

export function isKeyPressEvent(event: any): boolean {
  return event?.type === DOMEvents.keyPress;
}

export function removeClass(element: any, className: string): void {
  element?.classList?.remove?.(className);
}

export function setContent(element: any, content: string): void {
  element.innerHTML = content;
}

export function setStyle(element: any, property: string, value: any): void {
  element?.style?.setProperty?.(property, value, CSS.Options.important);
}

export function show(element: any, display: string = CSS.Values.block): void {
  element.style.setProperty(CSS.Props.display, display, CSS.Options.important);
}

export function triggerEvent(event: any, data?: any): void {
  window.dispatchEvent(new window.CustomEvent(event, { detail: data }));
}
