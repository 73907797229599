import { Call } from "../types/call";
import Generator from "./generator";

export class CallsHandler {
  private static _call?: Call;
  private static interval: NodeJS.Timeout;

  static get call() {
    return this._call;
  }

  static startCall = () => {
    clearInterval(this.interval);

    this._call = {
      key: Generator.getId(),
      duration: 0,
    };
    this.interval = setInterval(() => {
      if (this._call) {
        this._call.duration++;
      }
    }, 1000);
  };

  static endCall = () => {
    clearInterval(this.interval);

    this._call = undefined;
  };
}
