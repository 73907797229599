import Storage from "../utilities/storage/storage";
import { StorageKeys } from "../utilities/storage/storage_keys";

export class State {
  static get isChatInitiated(): boolean {
    return Storage.getKeyData(StorageKeys.CHAT_INITIATED) === true;
  }

  static initiateChat(): void {
    Storage.setKey(StorageKeys.CHAT_INITIATED, { data: true });
  }
}
