import { API } from "../service/api";
import { Integration } from "./integration";
import Storage from "./storage/storage";
import { StorageKeys } from "./storage/storage_keys";

export class Context {
  static get dealershipId() {
    return Storage.getKeyData<number>(StorageKeys.DEALERSHIP_ID) ?? NaN;
  }

  static initialize = async () => {
    const id = Storage.getKeyData<number>(StorageKeys.DEALERSHIP_ID);

    if (!id) {
      let update: number | undefined;

      if (+Integration.dealershipUUID) {
        update = +Integration.dealershipUUID;
      } else {
        const response = await API.getDealershipID(Integration.dealershipUUID);

        if (response.id) {
          update = response.id;
        }
      }

      if (update) {
        Storage.setKey(StorageKeys.DEALERSHIP_ID, { data: update });
      } else {
        throw new Error("Error getting dealership ID");
      }
    }
  };
}
