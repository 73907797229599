import TimeUnits from "../../service/time_units";

export default class Duration {
  value: number;
  unit: TimeUnits;

  constructor(value: number, unit: TimeUnits) {
    this.value = value;
    this.unit = unit;
  }

  toMilliseconds(): number {
    let multiplier: number;

    switch (this.unit) {
      case TimeUnits.milliseconds:
        multiplier = this.millisecond;
        break;
      case TimeUnits.seconds:
        multiplier = this.second;
        break;
      case TimeUnits.minutes:
        multiplier = this.minute;
        break;
      case TimeUnits.hours:
        multiplier = this.hour;
        break;
      case TimeUnits.days:
        multiplier = this.day;
        break;
    }

    return this.value * multiplier;
  }

  private get millisecond(): number {
    return 1;
  }

  private get second(): number {
    return 1000;
  }

  private get minute(): number {
    return 60 * this.second;
  }

  private get hour(): number {
    return 60 * this.minute;
  }

  private get day(): number {
    return 24 * this.hour;
  }
}
