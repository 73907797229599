import { Execute, IFRAME_ID } from ".";

import { API } from "./service/api";
import { ActivityResponse } from "./types/activity_response";
import { AppConfig } from "./app.config";
import { Backend } from "./service/backend/backend";
import { CallsHandler } from "./utilities/calls_handler";
import { ChatButton } from "./components/chat_button/ChatButton";
import { ChatClosed } from "./components/chat_button/state/chat_closed";
import { ChatSignals } from "./utilities/chat_signals";
import { ChatState } from "./components/chat_button/state/chat_state";
import { ChatTopics } from "./utilities/chat_topics";
import { ChatWindow } from "./components/chat_window/ChatWindow";
import { Configuration } from "./model/configuration";
import { Context } from "./utilities/context";
import { Datetime } from "./utilities/datetime";
import Factory from "../service/factory";
import { Geolocator } from "./service/geolocator/geolocator";
import HTTPResponse from "../service/http/http_response";
import { IdlenessHandler } from "./service/idleness/idleness_handler";
import { Preferences } from "./model/preferences";
import { RTSRequest } from "./types/rts_request";
import { State } from "./model/state";
import { StorageListener } from "./service/storage/storage_listener";
import { Store } from "./service/store/store";
import { Tracker } from "./service/tracker/tracker";
import { Visitor } from "./model/visitor";
import { isDocumentFocused } from "../service/dom/dom_utilities";
import { isUndefined } from "../service/lang";
import { SmartPixl } from "./components/smart_pixl/SmartPixl";
import style from "./components/theme.css";
import styleButton from "./components/chat_button/chat_button.css";
import styleWindow from "./components/chat_window/chat_window.css";

declare global {
  var QoreAI: {
    openChat: () => Promise<void>;
    execute: Execute;
  };
  var _qoreai_running: boolean;
}

export class App {
  private _backend = new Backend();
  private _chatButton: HTMLDivElement | undefined;
  private _chatState: ChatState | undefined;
  private _chatWindow: HTMLDivElement | undefined;
  private _geolocator = new Geolocator();
  private _store = new Store();
  private _timeCounter: any;
  private _tracker: Tracker = Factory.instance.build(Tracker);
  private _executed = false;

  get chatButton(): HTMLDivElement {
    if (isUndefined(this._chatButton)) {
      this._chatButton = ChatButton({ onClick: this.openChat.bind(this) });
    }

    return this._chatButton;
  }

  get chatState(): ChatState {
    if (isUndefined(this._chatState)) {
      this._chatState = new ChatClosed(this);
    }

    return this._chatState;
  }

  get chatWindow() {
    if (isUndefined(this._chatWindow)) {
      if (!Visitor.id) {
        throw new Error("No visitor ID!");
      }

      this._chatWindow = ChatWindow({
        visitorId: Visitor.id,
        dealershipId: Visitor.dealershipId,
      });
    }

    return this._chatWindow;
  }

  get isChatButtonEnabled(): boolean {
    return !this.chatButton.classList.contains(style.disabled);
  }

  blockUser(): void {
    this.closeChat();
    Visitor.block();
    this.chatButton.remove();
  }

  closeChat(): void {
    if (Preferences.isChatOpened) {
      this.chatState.toggle();
      this.chatState.end();
    }
  }

  disableChatButton(): void {
    this.chatButton.classList.add(style.disabled);
  }

  enableChatButton(): void {
    this.chatButton.classList.remove(style.disabled);
  }

  hideChatButton(): void {
    this.chatButton.classList.add(styleButton.slideOut);
  }

  hideChatWindow(): void {
    this.chatWindow.classList.remove(styleWindow.slideIn);
    this.chatWindow.classList.add(styleWindow.slideOut);
  }

  protected initializeChat(): void {
    document.body.appendChild(this.chatButton);

    if (Visitor.isRegistered) {
      document.body.appendChild(this.chatWindow);
    }

    if (Preferences.isChatOpened) {
      this.chatState.toggle();
    }
  }

  protected initializeSmartPixl(pixelId: string, pixelDomain: string): void {
    const smartPixl = SmartPixl(pixelId, pixelDomain);
    document.body.appendChild(smartPixl);
  }

  protected initializeMessageListener(): void {
    window.addEventListener("message", this.onMessage.bind(this));
  }

  protected initializeTimeCounter(): void {
    const interval = AppConfig.TIME_COUNTER_INTERVAL_SECONDS.toMilliseconds();
    const callback = () => {
      if (isDocumentFocused()) {
        Visitor.addTimeSpent(interval / 1000);
      }
    };

    this._timeCounter && clearInterval(this._timeCounter);
    this._timeCounter = setInterval(callback, interval);
  }

  private async sendActivity(): Promise<void> {
    if (
      Visitor.isRegistered &&
      State.isChatInitiated &&
      Preferences.isChatOpened
    ) {
      this._backend
        .notifyChatActivity()
        .then(this.handleActivityResponse.bind(this));
    }
  }

  private handleActivityResponse(
    response: HTTPResponse<ActivityResponse>
  ): void {
    const isOpened = Preferences.isChatOpened;
    const shouldClose = !!response.data?.isChatClosed;
    const isClosed = !Preferences.isChatOpened;
    const shouldOpen = !response.data?.isChatClosed;

    if ((isOpened && shouldClose) || (isClosed && shouldOpen)) {
      this.chatState.toggle();
    }
  }

  private async sendRtsActivity(): Promise<void> {
    await API.postRTSData(RTSRequest.build());
  }

  protected scheduleActivity(): void {
    this.sendActivity();

    setTimeout(
      () => this.scheduleActivity(),
      AppConfig.CHAT_ACTIVITY_POLL_INTERVAL.toMilliseconds()
    );
  }

  private async scheduleRtsActivity(): Promise<void> {
    try {
      await this.sendRtsActivity();
    } finally {
      setTimeout(
        () => this.scheduleRtsActivity(),
        AppConfig.RTS_POLL_INTERVAL.toMilliseconds()
      );
    }
  }

  protected onMessage(message: any): void {
    switch (message.data) {
      case ChatTopics.ACTIVITY:
        IdlenessHandler.getInstance().resetTimer();

        break;
      case ChatTopics.CLOSE_WINDOW:
        this.closeChat();

        break;
      case ChatTopics.INITIATED:
        if (!State.isChatInitiated) {
          this.chatState.start();
        }

        break;
      case ChatTopics.MESSAGE_RECEIVED:
      case ChatTopics.UNREAD_MESSAGES:
        this.openChat();

        break;
      case ChatTopics.MESSAGE_SENT:
        this.notifyRtsForMessageSent();

        break;
      case ChatTopics.USER_BLOCKED:
        this.blockUser();

        break;
      case ChatTopics.CALL_STARTED:
        CallsHandler.startCall();

        break;
      case ChatTopics.CALL_ENDED:
        CallsHandler.endCall();

        break;
      default:
        console.warn(`Chat topic ${message.data} not implemented!`);
        break;
    }
  }

  protected async openChat(): Promise<void> {
    if (!Preferences.isChatOpened) {
      await this.chatState.toggle();
      State.isChatInitiated && this._backend.startChat();
      const frame = document.getElementById(IFRAME_ID) as HTMLIFrameElement;
      frame.contentWindow?.postMessage(ChatSignals.SCROLL_TO_BOTTOM, "*");
    }
  }

  private execute: Execute = async (options = {}) => {
    if (!this._executed) {
      this._executed = true;
      await Visitor.initialize(options.fingerprint);
      this._tracker.parseCampaigns();
      Visitor.incrementPageViews();
      this.initializeTimeCounter();
      this._store.setLastPageViewTimestamp(Datetime.timestamp);
      this.initializeMessageListener();
      this.initializeChat();
      this.scheduleActivity();
      this.scheduleRtsActivity();
      this.initializeStorageListener();
      this.initializeIdlenessHandler();
    }
  };

  run = async () => {
    if (window._qoreai_running) {
      return console.warn("QoreAI script is already running");
    }

    window._qoreai_running = true;

    if (!Visitor.isBlocked) {
      try {
        await Context.initialize();
        await Configuration.initialize();

        if (Configuration.chatEnabled) {
          if (!Configuration.awaitScriptExecution) {
            this.execute();
          }

          window.QoreAI = {
            openChat: this.openChat.bind(this),
            execute: this.execute.bind(this),
          };
        }

        /* Todo: Implement SmartPixl Configuration and DealerCode Parameter */
        if (Configuration.pixelId && Configuration.pixelDomain) {
          this.initializeSmartPixl(Configuration.pixelId, Configuration.pixelDomain);
        }

      } catch (error) {
        console.error(error);
      }
    }
  };

  private initializeStorageListener(): void {
    StorageListener.getInstance().setContext(this).run();
  }

  private initializeIdlenessHandler(): void {
    IdlenessHandler.getInstance()
      .setCloseChat(this.closeChat.bind(this))
      .setIdleTimeoutSeconds(Configuration.closeDuration)
      .run();
  }

  setChatState(update: ChatState): void {
    this._chatState = update;
  }

  showChatButton(): void {
    this.chatButton.classList.remove(styleButton.slideOut);
  }

  showChatWindow(): void {
    this.chatWindow.classList.add(styleWindow.slideIn);
    this.chatWindow.classList.remove(styleWindow.slideOut);
  }

  private async notifyRtsForMessageSent(): Promise<void> {
    this._backend.postRtsVisitorMessage({
      dealershipId: Visitor.dealershipId,
      visitorId: Visitor.id,
    });
  }
}
