import { App } from "../../app";
import { StorageKeys } from "../../utilities/storage/storage_keys";

/**
 * Listens the storage events of the window. Used to synchronize the chat state
 * accross tabs.
 */
export class StorageListener {
  private static instance: StorageListener;
  private context?: App;
  private initialized = false;

  static getInstance(): StorageListener {
    if (!this.instance) {
      this.instance = new StorageListener();
    }

    return this.instance;
  }

  setContext(context: App): StorageListener {
    this.context = context;

    return this;
  }

  run(): void {
    if (!this.initialized) {
      this.validate();
      this.initializeStorageListener();
      this.initialized = true;
    }
  }

  private validate(): void {
    if (!this.context) {
      throw new Error("No context provided");
    }
  }

  private initializeStorageListener(): void {
    window.addEventListener("storage", this.onStorageEvent.bind(this));
  }

  private onStorageEvent(event: StorageEvent): void {
    switch (event.key) {
      case StorageKeys.CHAT_STATUS:
        this.onChatStatusChanged(event);

        break;
    }
  }

  private onChatStatusChanged(_event: StorageEvent): void {
    this.context?.chatState?.toggle?.();
  }
}
