export function csvToArray(source: any): string[] {
  let array: string[] = [];

  if (isString(source)) {
    array = source.split(',').map(s => s.trim()).filter(s => !isEmpty(s));
  }

  return array;
}

export function isArray(source: any): source is any[] {
  return Object.prototype.toString.call(source) === '[object Array]';
}

export function isBoolean(source: any): source is boolean {
  return source === true || source === false || Object.prototype.toString.call(source) === '[object Boolean]';;
}

export function isDate(source: any): source is Date {
  return source && Object.prototype.toString.call(source) === "[object Date]" && !isNaN(source);
}

export function isEmpty(source: any): boolean {
  let result;

  if (isArray(source) || isString(source)) {
    result = source.length === 0;
  } else if (isObject(source)) {
    result = Object.keys(source).length === 0;
  } else {
    result = true;
  }

  return result;
}

export function isNull(source: any): source is null {
  return source === null;
}

export function isNumber(source: any): source is number {
  return typeof source === 'number' && !isNaN(source);
}

export function isObject(source: any): boolean {
  return source !== null && typeof source === 'object' && !isArray(source);
}

export function isString(source: any): source is string {
  return typeof source === 'string' || source instanceof String;
}

export function isUndefined(source: any): source is undefined {
  return source === undefined;
}

export function maxBy(source: any, predicate: string | Function): any {
  let max: any;

  if (isArray(source)) {
    const getValue = (item: any) => isString(predicate) ? item?.[predicate] : predicate(item);

    source.forEach((item: any) => {
      const value = getValue(item);

      if (!isUndefined(value)) {
        if (isUndefined(max)) {
          max = item;
        } else {
          const maxValue = getValue(max);

          if (value > maxValue) {
            max = item;
          }
        }
      }
    });
  }

  return max;
}

export function parseBoolean(source: any): boolean {
  let result: boolean = false;

  if (isBoolean(source)) {
    result = source;
  }

  return result;
}

export function parseDate(source: any): Date | undefined {
  let date: Date | undefined;

  if (isDate(source)) {
    date = source;
  } else if (isString(source) || isNumber(source)) {
    const parsed = new Date(source);

    if (isDate(parsed) && !isNaN(parsed as unknown as number)) {
      date = parsed;
    }
  }

  return date;
}

export function parseNumber(source: any): number | undefined {
  let result: number | undefined;

  if (isNumber(source)) {
    result = source;
  } else if (isString(source)) {
    const parsed = parseInt(source);

    if (!isNaN(parsed)) {
      result = parsed;
    }
  }

  return result;
}

export function parseString(source: any): string | undefined {
  let result: string | undefined;

  if (isString(source)) {
    result = source;
  }

  return result;
}

export function sample(source: any): any {
  let result: any;

  if (isArray(source) && !isEmpty(source)) {
    const random = Math.random();
    const length = source.length;
    const index = Math.floor(random * length);
    result = source[index];
  }

  return result;
}

export function throwError(message?: string): void {
  throw isString(message) ? new Error(message) : new Error();
}
