// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Voc8YTDqUWmu7A4s9YXQ {
  border-radius: 15px;
}

._EG5QmUtzmPWsi0knJqa {
  opacity: 0.5;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.BhA_Q_Tb6LWwC3pvv9Jb {
  box-shadow: 0px 0px 10px 2px #9e9e9e;
}`, "",{"version":3,"sources":["webpack://./src/components/theme.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".border {\n  border-radius: 15px;\n}\n\n.disabled {\n  opacity: 0.5;\n  pointer-events: none;\n  user-select: none;\n}\n\n.shadow {\n  box-shadow: 0px 0px 10px 2px #9e9e9e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": `Voc8YTDqUWmu7A4s9YXQ`,
	"disabled": `_EG5QmUtzmPWsi0knJqa`,
	"shadow": `BhA_Q_Tb6LWwC3pvv9Jb`
};
export default ___CSS_LOADER_EXPORT___;
