// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dHI6X_PjrblH7QIKfdcC {
  align-items: center;
  bottom: 20px;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 20px;
  transition-property: bottom;
  transition-duration: .5s;
  z-index: 2147483647;
}

.C_Bnk5zXijnGlydzSj1U {
  animation: r5d2bHO8gpSuap10YY4k .5s;
  transition: opacity .2s;
}

.C_Bnk5zXijnGlydzSj1U:hover {
  opacity: .75;
  cursor: pointer;
}

.rzI1wa9Sq8fw_vSg_Glg {
  bottom: -80px;
  pointer-events: none;
}

@keyframes r5d2bHO8gpSuap10YY4k {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/chat_button/chat_button.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,WAAW;EACX,2BAA2B;EAC3B,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,mCAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  align-items: center;\n  bottom: 20px;\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  right: 20px;\n  transition-property: bottom;\n  transition-duration: .5s;\n  z-index: 2147483647;\n}\n\n.icon {\n  animation: popInit .5s;\n  transition: opacity .2s;\n}\n\n.icon:hover {\n  opacity: .75;\n  cursor: pointer;\n}\n\n.slideOut {\n  bottom: -80px;\n  pointer-events: none;\n}\n\n@keyframes popInit {\n  0% {\n    transform: scale(0);\n  }\n\n  50% {\n    transform: scale(1.15);\n  }\n\n  100% {\n    transform: scale(1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `dHI6X_PjrblH7QIKfdcC`,
	"icon": `C_Bnk5zXijnGlydzSj1U`,
	"popInit": `r5d2bHO8gpSuap10YY4k`,
	"slideOut": `rzI1wa9Sq8fw_vSg_Glg`
};
export default ___CSS_LOADER_EXPORT___;
