export class Cookie {
  static getByName = (name: string) => {
    return (
      document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ""
    );
  };

  static test3rdParty = (callback: (enabled: boolean) => void) => {
    const test = () => {
      const messageHandler = (event: MessageEvent) => {
        const data = JSON.parse(event.data);

        callback(data.result);
        window.removeEventListener("message", messageHandler);
        window.document.body.removeChild(frame);
      };

      window.addEventListener("message", messageHandler);

      const frame = window.document.createElement("iframe");
      frame.src = `${process.env.URL_BASE}/cookie_check.html`;

      frame.setAttribute("style", "display:none");

      const loadHandler = () => {
        if (frame.contentWindow) {
          frame.contentWindow.postMessage(
            JSON.stringify({ test: "cookie" }),
            "*"
          );
        }
      };
      frame.onload = loadHandler;

      window.document.body.appendChild(frame);
    };

    if (window.document.body) {
      test();
    } else {
      window.onload = test;
    }
  };
}
