namespace CSS {
  export class Options {
    static readonly important = 'important';
  }

  export class Props {
    static readonly borderColor = 'border-color';
    static readonly display = 'display';
    static readonly opacity = 'opacity';
  }

  export class Values {
    static readonly block = 'block';
    static readonly inlineBlock = 'inline-block';
    static readonly none = 'none';
  }
}

export default CSS;
