import { Call } from "./call";
import { CallsHandler } from "../utilities/calls_handler";
import { DeviceTypes } from "../utilities/device_types";
import { Visitor } from "../model/visitor";

export class RTSRequest {
  readonly dealershipId: number = NaN;
  readonly deviceType: DeviceTypes = DeviceTypes.DESKTOP;
  readonly duration: number = 0;
  readonly pageViewId: string = "";
  readonly sessionId: string = "";
  readonly url: string = "";
  readonly visitorId: string = "";
  readonly zip: string = "";
  fingerprint?: string;
  call?: Call;

  static build(): RTSRequest {
    const data: RTSRequest = {
      dealershipId: Visitor.dealershipId,
      deviceType: Visitor.deviceType,
      duration: Visitor.timeSpentPageView,
      pageViewId: Visitor.pageViewId,
      sessionId: Visitor.sessionId,
      url: window.location.href,
      visitorId: Visitor.id,
      zip: Visitor.zip,
    };

    if (CallsHandler.call) {
      data.call = CallsHandler.call;
    }

    return data;
  }
}
