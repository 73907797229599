import { Store } from "../store/store";

export class IdlenessHandler {
  private static instance: IdlenessHandler;
  private readonly HANDLER_INTERVAL = 1000;
  private closeChat?: VoidFunction;
  private idleTimeoutSeconds?: number;
  private initialized = false;
  private store = Store.getInstance();

  private get activityTimestamp(): number {
    return this.store.getActivityTimestamp() ?? this.getCurrentTimestamp();
  }

  private set activityTimestamp(update: number) {
    this.store.setActivityTimestamp(update);
  }

  static getInstance(): IdlenessHandler {
    if (!this.instance) {
      this.instance = new IdlenessHandler();
    }

    return this.instance;
  }

  setCloseChat(closeChat: VoidFunction): IdlenessHandler {
    this.closeChat = closeChat;

    return this;
  }

  setIdleTimeoutSeconds(idleTimeoutSeconds: number): IdlenessHandler {
    this.idleTimeoutSeconds = idleTimeoutSeconds;

    return this;
  }

  run(): void {
    if (!this.initialized) {
      this.validate();
      this.initializeListeners();
      this.initializeHandler();
      this.initialized = true;
    }
  }

  resetTimer(): void {
    this.activityTimestamp = this.getCurrentTimestamp();
  }

  private getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  private validate(): void {
    if (!this.closeChat) {
      throw new Error("No closeChat callback provided");
    }

    if (!this.idleTimeoutSeconds) {
      throw new Error("No idle timeout is set");
    }
  }

  private initializeListeners(): void {
    this.addWindowListener("mousemove");
    this.addWindowListener("keypress");
    this.addWindowListener("click");
  }

  private addWindowListener(eventKey: string): void {
    window.addEventListener(eventKey, this.resetTimer.bind(this));
  }

  private initializeHandler(): void {
    setInterval(this.handleIdleness.bind(this), this.HANDLER_INTERVAL);
  }

  private handleIdleness(): void {
    const diff = this.getCurrentTimestamp() - this.activityTimestamp;
    const threshold = (this.idleTimeoutSeconds as number) * 1000;

    if (diff >= threshold) {
      this.closeChat?.();
    }
  }
}
