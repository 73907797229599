import Duration from "./duration";

export class Datetime {
  static get timestamp(): number {
    return new Date().getTime();
  }

  static getFutureTimestamp(duration: Duration): number {
    return this.timestamp + duration.toMilliseconds();
  }
}
