import { BlockedStatusResponse } from "../types/blocked_status_response";
import { Context } from "../utilities/context";
import { DealershipConfiguration } from "../types/dealership_configuration";
import { RTSRequest } from "../types/rts_request";
import { RtsResponse } from "../types/rts_response";
import { Visitor } from "../model/visitor";

const headers = {
  "Content-Type": "application/json",
};

export class API {
  static getBlockedStatus = async (): Promise<BlockedStatusResponse> => {
    const response = await fetch(
      `${process.env.URL_BASE_API}/visitors/${Visitor.id}/status`,
      { headers }
    );

    return response.json();
  };

  static getConfiguration = async (): Promise<DealershipConfiguration> => {
    const response = await fetch(
      `${process.env.URL_BASE_API}/dealership/configuration?id=${Context.dealershipId}`,
      { headers }
    );

    return response.json();
  };

  static postRTSData = async (data: RTSRequest): Promise<RtsResponse> => {
    const response = await fetch(`${process.env.URL_BASE_RTS}/payload`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        ...headers,
        sectok: process.env.RTS_SECTOK!,
      },
    });

    return response.json();
  };

  static getDealershipID = async (uuid: string): Promise<{ id?: number }> => {
    const response = await fetch(
      `${process.env.URL_BASE_API}/dealership/${uuid}`
    );

    return await response.json();
  };
}
