import { AdCampaigns } from "../../utilities/ad_campaigns";
import BaseURLService from "../../../service/url/base/base_url_service";
import { Cookie } from "../cookie/cookie";
import { Cookies } from "../../utilities/cookies";
import Factory from "../../../service/factory";
import { Store } from "../store/store";
import URLService from "../../../service/url/url_service";

export class Tracker {
  private _store: Store = Factory.instance.build(Store);
  private _url: URLService = Factory.instance.build(BaseURLService);

  getCampaigns(): any {
    const campaigns = this._store.getCampaigns() ?? {};
    this.setGaId(campaigns);

    return campaigns;
  }

  parseCampaigns(): void {
    this.parseCampaign(AdCampaigns.GOOGLE);
    this.parseCampaign(AdCampaigns.FACEBOOK);
    this.parseCampaign(AdCampaigns.MICROSOFT);
  }

  private parseCampaign(campaign: string): void {
    const campaigns = this._store.getCampaigns() ?? {};

    if (!campaigns[campaign]) {
      const params = this._url.getCurrentSearchParams();
      const pattern = new RegExp(`^${campaign}$`, "i");

      for (let param in params) {
        if (!!params[param] && pattern.test(param)) {
          campaigns[campaign] = params[param];
          this._store.setCampaigns(campaigns);
        }
      }
    }
  }

  private setGaId(campaigns: any): void {
    const _ga = Cookie.getByName(Cookies.GA_CLIENT_ID);

    if (!!_ga) {
      campaigns.ga = _ga;
    }
  }
}
