import {
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from "../../../service/lang";

import { Datetime } from "../datetime";
import Duration from "../duration";
import { Integration } from "../integration";
import StorageItem from "./storage_item";
import TimeUnits from "../../../service/time_units";

export default class Storage {
  static get isEnabled(): boolean {
    let enabled: boolean;
    const TEST_KEY = "test";

    try {
      const hasTestKey = (): boolean => this.getKeyData(TEST_KEY) === true;
      const setTestKey = (): void => this.setKey(TEST_KEY, { data: true });

      if (hasTestKey()) {
        enabled = true;
      } else {
        setTestKey();
        enabled = hasTestKey();
      }
    } catch (error) {
      enabled = false;
    }

    return enabled;
  }

  static get sessionExpiry() {
    const sessionMinutes = parseInt(
      process.env.SESSION_DURATION_MINUTES ?? "30"
    );
    const sessionDuration = new Duration(sessionMinutes, TimeUnits.minutes);

    return Datetime.getFutureTimestamp(sessionDuration);
  }

  static deleteKey(key: string): void {
    window.localStorage.removeItem(this.prefixKey(key));
  }

  static extendExpiry(key: string, expiry: number): void {
    const data: any = this.getKeyData(key);

    if (!isUndefined(data) && isNumber(expiry)) {
      this.setKey(key, { data: data, expiry: expiry });
    }
  }

  static extendExpiryForSession = (key: string) => {
    this.extendExpiry(key, this.sessionExpiry);
  };

  static getKey<T>(key: string): StorageItem<T> | undefined {
    let storageItem: StorageItem<T> | undefined;
    const source: string | null = window.localStorage.getItem(
      this.prefixKey(key)
    );

    if (isString(source)) {
      const parsed: any = JSON.parse(source);

      if (isObject(parsed)) {
        const expiry: any = parsed?.expiry;

        if (isNumber(expiry)) {
          const isExpired = Datetime.timestamp > expiry;

          if (isExpired) {
            this.deleteKey(key);
          } else {
            storageItem = parsed;
          }
        } else {
          storageItem = parsed;
        }
      }
    }

    return storageItem;
  }

  static getKeyData<T>(key: string) {
    return this.getKey<T>(key)?.data;
  }

  static getKeyDataLegacy(key: string): any {
    return (this.getKey(key) as any)?.value;
  }

  static hasKey(key: string): boolean {
    return !isNull(window.localStorage.getItem(this.prefixKey(key)));
  }

  static setKey(key: string, item: StorageItem): void {
    window.localStorage.setItem(this.prefixKey(key), JSON.stringify(item));
  }

  static setKeyForSession = (key: string, data: any) => {
    this.setKey(key, { data, expiry: this.sessionExpiry });
  };

  static prefixKey = (key: string) => {
    return `${Integration.dealershipUUID}:${key}`;
  };
}
