import { AppConfig } from "../../app.config";
import { Context } from "../../utilities/context";
import HTTPResponse from "../../../service/http/http_response";
import HTTPStatus from "../../../service/http/http_status";
import HttpClient from "../../../service/http/base/base_http_service";
import { RegisterVisitorRequest } from "../../types/register_visitor_request";
import { RtsVisitorMessageRequest } from "../../types/rts_visitor_message_request";
import { Visitor } from "../../model/visitor";
import { isUndefined } from "../../../service/lang";

export class Backend {
  private readonly _headers = {
    "Content-Type": "application/json",
  };
  private readonly _headersRts = {
    ...this._headers,
    sectok: process.env.RTS_SECTOK,
  };
  private _http = new HttpClient();

  endChat(): Promise<any> {
    if (isUndefined(Visitor.id)) {
      throw new Error("Undefined visitor ID when ending chat!");
    }

    return this._http.delete({
      headers: this._headers,
      url: AppConfig.URL_VISITOR_CHAT(Visitor.id),
    });
  }

  async notifyChatActivity(): Promise<any> {
    if (isUndefined(Visitor.id)) {
      return;
    }

    return this._http.post({
      data: {
        timeSpent: Visitor.timeSpent,
        pageViews: Visitor.pageViews,
        numberOfVisits: Visitor.visits,
        status: Visitor.type,
      },
      headers: this._headers,
      url: AppConfig.URL_CHAT_ACTIVITY(Visitor.id),
    });
  }

  async postRtsVisitorMessage(
    request: RtsVisitorMessageRequest
  ): Promise<HTTPResponse<any>> {
    return this._http.post({
      data: request,
      headers: this._headersRts,
      url: AppConfig.URL.RTS.CHAT.VISITOR,
    });
  }

  async registerVisitor(
    request: RegisterVisitorRequest
  ): Promise<HTTPResponse<any>> {
    return await this._http.post({
      data: request,
      headers: this._headers,
      url: AppConfig.URL.REGISTER_VISITOR,
    });
  }

  startChat(): Promise<any> {
    if (isUndefined(Visitor.id)) {
      throw new Error("Undefined visitor ID when starting chat!");
    }

    return this._http.post({
      headers: this._headers,
      url: AppConfig.URL_VISITOR_CHAT(Visitor.id),
    });
  }
}
