export default class Generator {
  static getId = (length: number = 12) => {
    const CHARS =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let id = "";

    for (let i = 0; i < length; i++) {
      const random = Math.random();
      const charIndex = random * CHARS.length;
      const actualIndex = Math.floor(charIndex);
      const char = CHARS.charAt(actualIndex);
      id += char;
    }

    return id;
  };
}
