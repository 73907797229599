export enum ChatTopics {
  ACTIVITY = "ACTIVITY",
  CLOSE_WINDOW = "CLOSE_WINDOW",
  INITIATED = "INITIATED",
  MESSAGE_SENT = "MESSAGE_SENT",
  USER_BLOCKED = "USER_BLOCKED",
  MESSAGE_RECEIVED = "MESSAGE_RECEIVED",
  UNREAD_MESSAGES = "UNREAD_MESSAGES",
  CALL_STARTED = "CALL_STARTED",
  CALL_ENDED = "CALL_ENDED",
}
