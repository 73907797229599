import { addClass } from "../../../service/dom/dom_utilities";
import style from "./chat_button.css";

export function ChatButton(props: { onClick: () => void }) {
  const icon = document.createElement("img");
  icon.classList.add(style.icon, "qoreai-chat-button-image");
  icon.src = `${process.env.URL_BASE}/chat_icon.svg`;
  icon.onclick = props.onClick;

  const container = document.createElement("div");
  addClass(container, style.container);
  addClass(container, "qoreai-chat-button-wrapper");
  container.appendChild(icon);

  return container;
}
